<template>
	<div class="user">
		<div class="videoBor">
			<div class="title">产业视频</div>
			<video ref="videoPlayer" :src="videoSrc" controls @play="onPlay" @pause="onPause" @ended="onEnded"
				:poster="posterImg" width="1000" height="600px">
				<source :src="videoSrc" type="video/mp4" />
			</video>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				videoSrc: 'https://ln.shunlushuzi.com/uploads/10.mp4',
				posterImg:require('@/static/img/poster.png'),
			}
		},
		mounted() {
			document.title = '广东乐养堂大健康';
		},
		methods: {
			onPlay() {
				console.log('播放了')
			},
			onPause() {
				console.log('暂停了')
			},
			onEnded() {
				console.log('结束了')
			},
		}
	}
</script>

<style lang="scss">
	.user {
		width: 100%;
		height: auto;
		margin-top: 100px;

		>.videoBor {
			width: 100%;
			height: 600px;
			padding-top: 50px;
			padding-bottom: 200px;
			>.title{
				width: 100%;
				height: 100px;
				text-align: center;
				line-height: 100px;
				font-size: 35px;
				color: #004b00;
			}
		}
	}
</style>