<template>
	<div class="home">
		<div class="swiper">
			<el-carousel height="500px" :interval='2000'>
				<el-carousel-item v-for="(item,index) in swiperLst" :key="index">
					<h3 :style="{backgroundImage: 'url(' + item.img + ')' }"></h3>
				</el-carousel-item>
			</el-carousel>
		</div>
		<!-- <div class="API">
			<div class="center">
				<div class="list" v-for="(item,index) in gklst" :key="index">
					<div class="name">{{item.name}}</div>
					<div class="time">{{item.time}}</div>
				</div>
				<div class="list">
					<div class="qita">
						<router-link to="/announce">
							<span>更多公告</span>
							<span><i class="el-icon-caret-right"></i></span>
						</router-link>
					</div>
				</div>
			</div>
		</div> -->
		<div class="programme">
			<div class="title">
				精准高效的解决方案
			</div>
			<div class="introduce">
				整合商品、渠道资源 行业领先的技术资源 驱动企业实现业绩增长
			</div>
			<div class="list">
				<div class="item" v-for="(item,index) in Solutionlst" :key="index">
					<div class="box">
						<div class="img">
							<img :src="item.img" alt="" />
						</div>
						<div class="name">
							{{item.title}}
						</div>
						<div class="describe">
							{{item.name}}
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="service2">
			<div class="name">
				康养中心
			</div>
			<div class="center">
				<img src="@/static/img/health10.jpg" alt="" />
				<img src="@/static/img/health12.jpg" alt="" />
				<img src="@/static/img/health11.jpg" alt="" />
				<img src="@/static/img/health7.jpg" alt="" />
			</div>
			<div class="introduce">
				健康小屋
			</div>
			<div class="list">
				<div class="item" v-for="(i,k) in serviceLst2" :key="k">
					<img :src="i.img" alt="" />
				</div>
			</div>
		</div>
		<!-- <div class="service">
			<div class="name">
				优选产品介绍
			</div>
			<div class="introduce">
				各种优质高效产品和医疗保健服务
			</div>
			<div class="list">
				<div class="item" v-for="(i,k) in serviceLst" :key="k">
					<img :src="i.img" alt="" />
					<div class="introduce">
						<div class="characteristic">
							<div>特点:</div>
							<p>{{i.characteristic1}}</p>
							<p>{{i.characteristic2}}</p>
							<p>{{i.characteristic3}}</p>
						</div>
						<div class="effect">
							<div>作用:</div>
							<p>{{i.effect}}</p>
						</div>
					</div>
				</div>
			</div>
		</div> -->
		<div class="ServiceItems">
			<div class="title">乐养堂项目</div>
			<div class="describe"></div>
			<div class="ServiceType">
				<div class="item" v-for="(item,index) in projectService" :key="index">
					<div class="text">
						<div class="name">
							{{item.title}}
						</div>
						<div class="content">
							{{item.text}}
							<!-- 1. 肩颈理疗 放松肌肉，提高脑供氧，改善失眠多梦，提亮肤色，是人人必选的明星养生项目
							2. 肩颈理疗 放松肌肉，提高脑供氧，改善失眠多梦，提亮肤色，是人人必选的明星养生项目
							3. 肩颈理疗 放松肌肉，提高脑供氧，改善失眠多梦，提亮肤色，是人人必选的明星养生项目 -->
						</div>
					</div>
					<div class="img">
						<img :src="item.img" alt="" />
					</div>
				</div>
			</div>
		</div>
		<div class="advantage">
			<div class="box">
				<div class="advantagename">
					合作优势
				</div>
				<div class="advantagetype" v-for="(item,index) in advantageLst" :key="index">
					<div class="title">{{item.title}}</div>
					<div class="line"></div>
					<div class="name">{{item.name}}</div>
				</div>
			</div>
		</div>
		<!-- <div class="Settle_in">
			<div class="inName">
				安装APP&nbsp;步骤教程
			</div>
			<div class="intype">
				<div class="item" v-for="(i,k) in SettleLst" :key="k">
					<div class="img">
						{{i.num}}
					</div>
					<div class="name">{{i.title}}</div>
					<div class="miaoshu">{{i.name}}</div>
				</div>
			</div>
		</div> -->
		<div class="Elegance">
			<div class="title">团建展示</div>
			<el-carousel :interval="2000" type="card" height="330px">
				<el-carousel-item v-for="(item,index) in showElegance" :key="index">
					<img style="width: 100%;height: 100%;" :src="item.img" alt="" />
				</el-carousel-item>
			</el-carousel>
		</div>
	</div>
</template>

<script>
	// import Login from '@/components/login.vue'
	// import Register from '@/components/register.vue'
	// import store from '../../store.js'
	// import axios from 'axios'
	export default {
		data() {
			return {
				shopList: [],
				swiperLst: [{
						'img': require('@/static/img/health13.png'),
						'url': '',
					},
					{
						'img': require('@/static/img/health15.jpg'),
						'url': '',
					},
					{
						'img': require('@/static/img/health14.jpg'),
						'url': '',
					},
				],
				gklst: [{
						name: '店铺外卖API接口正式开放',
						time: '2024-5-13',
					},
					{
						name: '排队免单活动正式上线',
						time: '2024-5-13',
					},
					{
						name: '商家入驻须知',
						time: '2024-5-13',
					},
					{
						name: '商家入驻须知',
						time: '2024-5-13',
					},
					{
						name: '商家入驻须知',
						time: '2024-5-13',
					},
				],
				Solutionlst: [{
						name: '覆盖淘宝、京东、拼多多、唯品会等导购优惠券，高效转化,商品搜索等众多API。',
						title: '导购电商场景',
						img: require('@/static/img/8.png')
					},
					{
						name: '覆盖影院、外卖、出行、团购等本地生活CPS返利，吃喝玩乐一站式消费，省钱又赚钱。',
						title: '吃喝玩乐场景',
						img: require('@/static/img/7.png')
					},
					{
						name: '全品类数字权益商品，覆盖话费，电费，燃气，水费等数字化标准接口。',
						title: '本地服务场景',
						img: require('@/static/img/6.png')
					},
					{
						name: '集合电商购物，本地生活，生活服务，娱乐影视等1000+权益特权,提供完整的企业福利解决方案。',
						title: '权益特权福利',
						img: require('@/static/img/5.png')
					},
				],
				serviceLst: [{
						img: require('@/static/img/good7.jpg'),
						effect: '提神醒脑',
						characteristic1: '1.气味清新，无不良影响',
						characteristic2: '',
						characteristic3: '',
					},
					{
						img: require('@/static/img/good10.jpg'),
						effect: '行气止痛、散瘀通络，舒缓肌肤',
						characteristic1: '1.普通的治疗疼痛产品，价格透明，市场饱和，可代替性高',
						characteristic2: '2.黑老虎气味不刺鼻，温和气味，易散不留味，接受率极高。',
						characteristic3: '3.经过我们无数次的产品测试以及大品牌合作反馈，同时通过不同性别年龄的测试，均是1至7天内有明显的疗效',
					},
					{
						img: require('@/static/img/good9.jpg'),
						effect: '改善神经传导功能状况、增强免疫系统功能，消除病灶组织酸中毒，减少炎症渗出物、促进组织再生',
						characteristic1: '1.电脑自动控制，安全可靠',
						characteristic2: '2.使用两组中频正弦电流治疗，a组固定频率在4000Hz，b组频率在4000-4150Hz，呈周期变化。',
						characteristic3: '3.两个治疗模式，IE模式:主要适用于各种类型骨折的愈合，预防骨延迟愈合、骨不愈合、各种软组织损伤的镇痛、消炎、消肿。干扰电模式：本模式是按照国际标准设计的一种较灵活的治疗方式，某范围内有规律地旋转变化，其差频也作相应旋转变化，在组织交叉处产生动态干扰场引起内生电流疗效区域及深度的旋转变化，克服了机体对幅度，频率恒定的中频交流电易于适应的缺点。',
					},
				],
				serviceLst2: [{
						img: require('@/static/img/environment1.jpg')
					},
					{
						img: require('@/static/img/environment3.jpg')
					},
					{
						img: require('@/static/img/environment2.jpg')
					},
					{
						img: require('@/static/img/environment4.jpg')
					},
				],
				advantageLst: [{
						name: '龙脑产业，助力全民健康',
						title: '行业优势',
					},
					{
						name: '省时、省力、省人工，效果立竿见影， 不囤货、不压货、不卖空盒，无压力做店',
						title: '项目优势',
					},
					{
						name: '秘制古方，超临界萃取，国家专利， 药监备案',
						title: '产品优势',
					},
					{
						name: '提供全方位的经营支持,帮助加盟商应对创业过程中的各种挑战，提高经营效率‌',
						title: '经营支持',
					},
					{
						name: '会根据市场需求和行业发展趋势，不断推出新的理疗项目和服务，保持品牌的竞争力和吸引力‌',
						title: '创新和研发',
					},
					{
						name: '适用人群广泛18-80岁，客源精准， 有消费能力，线上线下有机结合',
						title: '客源优势',
					},
				],
				SettleLst: [{
						num: '1.',
						title: '右上方下载',
						name: '微信扫码登录/注册即可'
					},
					{
						num: '2.',
						title: '创建应用',
						name: '选择相关类型进行创建应用'
					},
					{
						num: '3.',
						title: '选择并调试API',
						name: '使用微客云集开发平台提供的技术测试'
					},
					{
						num: '4.',
						title: '注册账户',
						name: '开发完成在服务市场上发布'
					},
				],
				showElegance: [{
						img: require('@/static/img/active1.jpg'),
					},
					{
						img: require('@/static/img/active2.jpg'),
					},
					{
						img: require('@/static/img/active3.jpg'),
					},
					{
						img: require('@/static/img/active4.jpg'),
					},
					{
						img: require('@/static/img/active5.jpg'),
					},
				],
				projectService: [{
						title: '养生保健服务',
						text: '提供安静舒适的服务环境和高效的医疗器材',
						img: require('@/static/img/environment1.jpg')
					},
					{
						title: '保健食品销售;',
						text: '拥有特产的健康食品',
						img: require('@/static/img/project2.jpg')
					},
					{
						title: '医学研究和试验发展',
						text: '',
						img: require('@/static/img/project1.png')
					},
					{
						title: '体育用品及器材批发',
						text: '律动健康一站式融合创新服务，震走疾病，动出健康',
						img: require('@/static/img/project3.jpg')
					},
				]
			}
		},
		mounted() {
			document.title = '广东乐养堂大健康';
		},
		methods: {

		}
	}
</script>

<style lang="scss">
	.home {
		width: 100%;
		height: auto;
		margin-top: 90px;

		>.swiper {
			width: 100%;
			min-width: 1600px;
			height: 600px;
			margin-top: 60px;
			margin: 0 auto;

			.el-carousel__item h3 {
				width: 100%;
				height: 650px;
				color: #ffffff;
				font-size: 58px;
				line-height: 460px;
				margin: 0;
				background-size: 100% 100%;
			}
		}

		.API {
			width: 100%;
			height: 120px;
			background-color: #fff;

			>.center {
				width: 1380px;
				height: 120px;
				margin: 0 auto;
				display: flex;
				justify-content: space-between;

				.list {
					width: 213px;
					height: 120px;

					.name {
						width: 143px;
						height: 44px;
						margin: 34px auto;
						font-size: 16px;
						font-weight: 400;
						color: #1e1e28;
						line-height: 22px;
						overflow-wrap: break-word;
						text-align: left;
						display: -webkit-box;
						-webkit-box-orient: vertical;
						-webkit-line-clamp: 2;
						/* 设置行数 */
						overflow: hidden;
					}

					.time {
						width: 153px;
						height: 20px;
						font-size: 14px;
						font-weight: 400;
						color: rgba(0, 0, 0, .45);
						line-height: 20px;
						margin: -25px -11px;
					}

					.qita {
						width: 70px;
						height: 52px;
						color: #0b4ffc;
						line-height: 52px;
						margin: 34px 30px;
						font-size: 14px;
						text-align: left;

						span {
							color: blue;
						}
					}
				}
			}
		}

		.programme {
			width: 1380px;
			height: 500px;
			margin: 0 auto;
			margin-top: 80px;

			.title {
				font-size: 32px;
				font-weight: 500;
				color: #004b00;
				line-height: 45px;
				text-align: center;
			}

			.introduce {
				margin-top: 25px;
				font-size: 20px;
				font-weight: 400;
				color: rgba(0, 0, 0, .45);
				line-height: 28px;
				text-align: center;
			}

			.list {
				width: 100%;
				height: 346px;
				margin: 50px auto;
				display: flex;

				.item {
					width: 345px;
					height: 276px;
					border: 1px solid #ededed;
					background: #fff;
					transition: height .5s;
					display: flex;
					flex-direction: column;
					align-items: center;
					margin-top: 35px;

					.box {
						width: 220px;
						height: 230px;
						margin: 40px 45px;

						.img {
							width: 40px;
							height: 40px;
							// background-color: blue;
							margin: 0 auto;

							img {
								width: 100%;
							}
						}

						.name {
							width: 108px;
							height: 51px;
							padding-top: 20px;
							font-size: 18px;
							color: #000000D9;
							margin: 0 auto;
						}

						.describe {
							width: 220px;
							height: 78px;
							margin-top: 10px;
							font-size: 14px;
							color: #00000073;
							margin: 10px auto;
							line-height: 26px;
						}
					}
				}
			}
		}

		>.service {
			width: 1380px;
			height: 1400px;
			margin: 0px auto;
			padding: 50px 0px;

			.name {
				width: 1380px;
				height: 50px;
				padding: 0px 0px 10px;
				font-size: 32px;
				color: #004b00;
				line-height: 50px;
			}

			.introduce {
				width: 1380px;
				height: 45px;
				line-height: 45px;
				color: #00000073;
				font-size: 20px;
				margin: 10px 0px 0px;
			}

			.list {
				width: 1180px;
				height: 1300px;
				margin: 30px auto;

				.item {
					width: 1180px;
					height: 430px;
					background-color: #fff;
					display: flex;
					justify-content: space-evenly;
					flex-wrap: wrap;
					margin-top: 10px;
					border-radius: 20px;

					>img {
						width: 430px;
						height: 390px;
						margin-top: 20px;
					}

					>.introduce {
						width: 700px;
						height: 390px;
						text-align: left;
						margin-top: 20px;

						>.characteristic {
							width: 100%;
							height: 270px;

							>div {
								width: 100%;
								height: 30px;
								font-size: 18px;
								text-align: left;
								color: #000;
								font-weight: bold;
							}

							>p {
								width: 100%;
								height: 35px;
								text-align: left;
								color: #000;
								font-size: 16px;
								padding-left: 10px;
							}
						}

						>.effect {
							width: 100%;
							height: 100px;
							font-size: 16px;

							>div {
								width: 100%;
								height: 30px;
								font-size: 18px;
								text-align: left;
								font-weight: bold;
								color: #000;
							}

							>p {
								text-align: left;
								padding-left: 10px;
								color: #004b00;
							}
						}
					}
				}
			}
		}

		>.service2 {
			width: 1380px;
			height: 1750px;
			margin: 50px auto;
			background-color: #ffffff;
			padding: 50px 0px;

			.name {
				width: 1380px;
				height: 80px;
				margin-top: 10px;
				padding: 0px 0px 15px;
				font-size: 32px;
				color: #004b00;
				line-height: 25px;
			}

			.center {
				width: 1380px;
				height: 750px;
				overflow: hidden;
				display: flex;
				justify-content: space-evenly;
				flex-wrap: wrap;

				>img {
					width: 650px;
					height: 350px;
					margin-bottom: 10px;
					border-radius: 10px;
				}
			}

			.introduce {
				width: 1380px;
				height: 70px;
				line-height: 70px;
				margin-top: 30px;
				color: #0c0c0c;
				font-size: 30px;
				font-weight: bold;
			}

			.list {
				width: 1000px;
				height: 600px;
				display: flex;
				justify-content: space-evenly;
				flex-wrap: wrap;
				margin: 30px auto;

				.item {
					width: 490px;
					height: 280px;
					margin-top: 10px;

					>img {
						width: 100%;
						height: 100%;
						border-radius: 10px;
					}
				}
			}
		}

		.advantage {
			width: 100%;
			height: auto;
			padding: 0px 280px;
			box-sizing: border-box;
			margin: 0 auto;
			background-color: #004b00;
			padding-top: 20px;

			>.box {
				width: 1380px;
				height: 420px;
				margin: 0 auto;
				display: flex;
				flex-wrap: wrap;

				>.advantagename {
					width: 260px;
					height: 190px;
					margin-left: 20px;
					color: #fff;
					text-align: left;
					font-size: 30px;
					font-weight: bold;
				}

				>.advantagetype {
					width: 285px;
					height: 190px;
					background-color: #fff;
					margin-right: 25px;
					margin-bottom: 20px;

					>.title {
						width: 100px;
						height: 90px;
						margin: 0 auto;
						color: #000;
						line-height: 135px;
						font-size: 20px;
						font-weight: bold;
						// border-bottom: 3px solid #004b00;
					}

					>.line {
						width: 40px;
						height: 3px;
						margin: 0 auto;
						background-color: #004b00;
					}

					>.name {
						width: 80%;
						height: 100px;
						font-size: 13px;
						margin: 10px auto;
					}
				}

				>.advantagetype:nth-child(4) {
					margin-right: 5px;
				}

				>.advantagetype:nth-child(5) {
					margin-left: 20px;
				}
			}



		}

		.ServiceItems {
			width: 100%;
			height: 620px;
			margin-bottom: 100px;
			margin-top: 50px;

			>.title {
				width: 1280px;
				height: 70px;
				text-align: center;
				line-height: 70px;
				font-size: 32px;
				margin: 0 auto;
				color: #004b00;
			}

			>.describe {
				width: 1280px;
				height: 70px;
				line-height: 50px;
				margin: 0 auto;
				color: #00000073;
			}

			>.ServiceType {
				width: 1110px;
				height: 460px;
				margin: 0 auto;
				display: flex;
				justify-content: space-between;
				flex-wrap: wrap;

				>.item {
					width: 550px;
					height: 200px;
					display: flex;
					background-color: #fff;
					box-shadow: 0px 5px 5px 2px rgba(0, 0, 0, 0.2);
					border-radius: 10px;

					>.text {
						width: 340px;
						height: 200px;
						padding: 0px 15px;

						>.name {
							width: 310px;
							height: 80px;
							line-height: 120px;
							font-size: 23px;
							font-weight: bold;
							text-align: left;
							color: #000;
						}

						>.content {
							width: 310px;
							height: 70px;
							display: -webkit-box;
							-webkit-box-orient: vertical;
							-webkit-line-clamp: 4;
							/* 设置显示的行数 */
							overflow: hidden;
							font-size: 13px;
							text-align: left;
							margin-top: 15px;
							color: #000;
						}
					}

					>.img {
						width: 200px;
						height: 200px;
						margin-right: 10px;

						>img {
							width: 200px;
							height: 170px;
							margin-top: 15px;
						}
					}
				}
			}
		}

		.Settle_in {
			width: 100%;
			height: 400px;
			background-color: #fff;
			margin: 80px -77px 0px 0px;
			padding: 80px 0px;

			.inName {
				width: 1180px;
				height: 40px;
				color: #004b00;
				font-size: 32px;
				padding: 0px 0px 15px;
				line-height: 45px;
				margin: 0 auto;
			}

			.intype {
				width: 1180px;
				height: 178px;
				margin: 80px 0px 0px;
				margin: 80px auto;

				.item {
					height: auto;
					background-color: #fff;
					margin-right: 40px;
					float: left;
					text-align: left;

					.img {
						width: 60px;
						height: 100px;
						// background-color: blue;
						color: #004b00;
						font-size: 70px;
						line-height: 100px;
						font-weight: bold;
					}

					.name {
						width: auto;
						height: 40px;
						color: #1e1e28;
						font-size: 28px;
					}

					.miaoshu {
						width: auto;
						height: 25px;
						color: #000000a6;
						font-size: 18px;
						margin-top: 15px;
					}
				}
			}

			.inbtn {
				width: 196px;
				height: 46px;
				color: #fff;
				font-size: 15px;
				background-color: #0b4ffc;
				margin: 100px 0px 0px 490px;
				line-height: 46px;
				margin: 0 auto;
			}
		}

		>.Elegance {
			width: 1180px;
			height: 500px;
			margin: 80px auto;
			background-color: #fff;

			>.title {
				width: 100%;
				height: 150px;
				text-align: center;
				line-height: 120px;
				font-size: 31px;
				color: #004b00;
			}
		}
	}

	@media screen and (max-width: 900px) {
		.home {
			width: 100%;
			height: auto;
			margin-top: 4.5rem;

			>.swiper {
				width: 100%;
				height: 12rem;
				margin: -3rem auto;

				.el-carousel__item h3 {
					width: 27rem;
					height: 13rem;
					color: #ffffff;
					font-size: 58px;
					line-height: 460px;
					margin-top: .5rem;
					background-size: 100% 100%;
				}
			}

			.API {
				width: 100%;
				min-height: 21rem;
				margin-top: 6rem;

				>.center {
					width: 27rem;
					height: 7rem;
					margin: 0 auto;
					display: flex;
					justify-content: space-between;
					flex-wrap: wrap;

					.list {
						width: 13.5rem;
						height: 7rem;
						box-shadow: 1px 1px 1px rgba(194, 194, 194, 0.3);

						.name {
							width: 10rem;
							height: 2.5rem;
							margin: 1.5rem auto;
							font-size: 0.9rem;
							font-weight: 400;
							color: #1e1e28;
							line-height: 1rem;
							overflow-wrap: break-word;
							text-align: left;
							display: -webkit-box;
							-webkit-box-orient: vertical;
							-webkit-line-clamp: 2;
							/* 设置行数 */
							overflow: hidden;
						}

						.time {
							width: 10rem;
							height: 20px;
							font-size: 0.8rem;
							font-weight: 400;
							color: rgba(0, 0, 0, .45);
							line-height: 1.2rem;
							margin: -1rem -1.2rem;
						}

						.qita {
							width: 10rem;
							height: 52px;
							color: #0b4ffc;
							line-height: 52px;
							margin: 2rem 1.8rem;
							font-size: 0.8rem;
							text-align: left;

							span {
								color: blue;
							}
						}
					}
				}
			}

			.programme {
				width: 27rem;
				height: 38rem;
				margin: 5rem auto;
				background-color: #fff;
				padding-top: 2rem;
				margin-bottom: 2rem;

				.title {
					font-size: 1.5rem;
					font-weight: 500;
					color: #1e1e28;
					line-height: 3rem;
					text-align: center;
				}

				.introduce {
					width: 25rem;
					margin: 1rem auto;
					font-size: 1rem;
					font-weight: 400;
					color: rgba(0, 0, 0, .45);
					line-height: 1.75rem;
					text-align: center;
				}

				.list {
					width: 27rem;
					height: 26rem;
					margin: 2rem auto;
					display: flex;
					flex-wrap: wrap;

					.item {
						width: 13.3rem;
						height: 13rem;
						border: 1px solid #ededed;
						background: #fff;
						transition: height .5s;
						display: flex;
						flex-direction: column;
						align-items: center;
						margin-top: 0rem;

						.box {
							width: 13.3rem;
							height: 13rem;
							margin: 0rem 1rem;
							// background-color: red;

							.img {
								width: 2rem;
								height: 2rem;
								margin: 1rem auto;

								img {
									width: 100%;
								}
							}

							.name {
								width: 6.75rem;
								height: 2rem;
								padding-top: 1rem;
								font-size: 18px;
								color: #000000D9;
								margin: 0 auto;
							}

							.describe {
								width: 11rem;
								height: 3rem;
								margin-top: 0.1rem;
								font-size: 0.9rem;
								color: #00000073;
								margin: 0.8rem auto;
								line-height: 1.5rem;
								overflow-wrap: break-word;
								display: -webkit-box;
								-webkit-box-orient: vertical;
								-webkit-line-clamp: 2;
								/* 设置行数 */
								overflow: hidden;
							}
						}
					}
				}
			}


			.service {
				width: 27rem;
				height: 70rem;
				margin: 10rem auto;
				margin-top: 0rem;
				padding: 3rem 0px;
				margin-bottom: 25rem;

				.name {
					width: 27rem;
					height: 1.5rem;
					padding: -1rem 0px 1rem;
					font-size: 1.2rem;
					color: #1e1e28;
					line-height: 2.8rem;
				}

				.introduce {
					width: 27rem;
					height: 1rem;
					color: #00000073;
					font-size: 1rem;
					margin: 1rem 0rem;
				}

				.list {
					width: 27rem;
					height: 60rem;
					display: flex;
					flex-wrap: wrap;
					margin: 2rem auto;

					.item {
						width: 27rem;
						height: 20rem;

						>img {
							width: 100%;
							height: 100%;
						}
					}
				}
			}

			.service2 {
				width: 27rem;
				height: 150rem;
				margin: 10rem auto;
				margin-top: 20rem;
				padding: 3rem 0px;
				margin-bottom: 110rem;

				.name {
					width: 27rem;
					height: 1.5rem;
					padding: -1rem 0px 1rem;
					font-size: 1.2rem;
					color: #1e1e28;
					line-height: 2.8rem;
				}

				.introduce {
					width: 27rem;
					height: 1rem;
					color: #00000073;
					font-size: 1rem;
					margin: 1rem 0rem;
				}

				.list {
					width: 27rem;
					height: 150rem;
					display: flex;
					flex-wrap: wrap;
					margin: 2rem auto;

					.item {
						width: 27rem;
						height: 53rem;

						>img {
							width: 100%;
							height: 100%;
						}
					}

				}
			}

			.advantage {
				width: 27rem;
				min-height: 32rem;
				padding: 2rem 0rem;
				margin: 0 auto;

				.advantagename {
					width: 27rem;
					height: 3rem;
					color: #1e1e28;
					font-size: 1.5rem;
					padding: 0px 0px 1rem;
					margin-top: -9rem;
				}

				.advantageIntroduce {
					width: 27rem;
					height: 1rem;
					color: #00000073;
					font-size: 1.25rem;
					margin-top: -1rem;
				}

				.advantageList {
					width: 27rem;
					height: 32rem;
					margin: 3rem 0rem 0rem 0.5rem;
					display: flex;
					flex-wrap: wrap;

					.item {
						width: 8.5rem;
						height: 15rem;
						background-color: #fff;
						margin-top: 0.5rem;

						.img {
							width: 4rem;
							height: 4rem;
							margin: -1rem auto;

							img {
								width: 100%;
							}
						}

						.itemname {
							width: 8rem;
							height: 2rem;
							color: #000000d9;
							font-size: 1rem;
							margin: 1.5rem auto;
							line-height: 2rem;
						}

						.itemintroduce {
							width: 8rem;
							height: 2rem;
							color: #00000073;
							font-size: 0.7rem;
						}
					}
				}
			}

			.Settle_in {
				width: 27rem;
				min-height: 48rem;
				background-color: #f9f9f9;
				margin: 1rem -5rem 0rem 0rem;
				margin-bottom: 25rem;

				.inName {
					width: 27rem;
					height: 2rem;
					color: #1e1e28;
					font-size: 1.5rem;
					padding: 0rem 0rem 1rem;
					margin: 0 auto;
				}

				.intype {
					width: 27rem;
					height: 43rem;
					margin: 1rem auto;

					.item {
						height: 27rem;
						height: 10rem;
						// background-color: #fff;
						margin-top: 0.3rem;

						.img {
							width: 7rem;
							height: 10rem;
							color: #004b00;
							font-size: 4.3rem;
							font-weight: bold;
							float: left;
							line-height: 10rem;
						}

						.name {
							width: 18rem;
							height: 2.5rem;
							color: #1e1e28;
							font-size: 1.5rem;
							float: left;
							margin: -7rem 6rem;
							text-align: left;
						}

						.miaoshu {
							width: 18rem;
							height: 1.5rem;
							color: #000000a6;
							font-size: 1rem;
							margin-top: 1rem;
							float: left;
							margin: -4.7rem 6rem;
							text-align: left;
						}
					}
				}

				.inbtn {
					width: 10rem;
					height: 3rem;
					color: #fff;
					font-size: 1rem;
					background-color: #0b4ffc;
					line-height: 3rem;
					margin: 0 auto;
					display: none;
				}
			}
		}
	}
</style>