<template>
	<div class="introduce_LYT">
		<div class="title">乐养堂是做什么的？</div>
		<h3>乐养堂：龙脑健康产业开拓者</h3>
		<ul>
			<li>
				乐养堂专注深耕龙脑健康产业，全力构建与拓展龙脑全产业链，
				目标三年内成为全国龙脑大王
			</li>
			<li>
				公司布局有龙脑种植加工生产基地，龙脑康养基地，龙脑健康中心，
				龙脑健康小屋，综合养老服务平台以及龙脑康养学院等机构
			</li>
			<li>
				公司通过自主研发的龙脑系列产品、现代康养设备、特有疗效的
				“龙脑五养体质管理系统”（乐养、食养、动养、静养、调养）
				和多元覆盖的“1+3中医康养服务体系”（康养教育＋自主康养、
				社区康养、文旅康养）为客户提供全面有效的中医健康管理方案，
				帮助客户恢复健康。
			</li>
		</ul>
		<h3>目前公司主打产品为龙脑精油</h3>
		<ul>
			<li>
				龙脑是一种名贵药材为万药之引，四大香之首，自古以来就是皇家贡品，
				具有醒脑、通窍、清肺的显著作用。
			</li>
			<li>
				其提取出来的“天然冰片”广泛应用于各种千古名方，如安宫牛黄丸，
				速效救心丸，片仔癀，云南白药等，其快速见效的特性深受广大消
				费型的好评。
			</li>
		</ul>
	</div>
</template>

<script>
	export default {
		data() {
			return {

			}
		},
		methods: {

		}
	}
</script>

<style lang="scss">
	@media screen and (max-width: 500px) {
		.introduce_LYT {
			width: 27rem;
			height: auto;
			text-align: left;
			>.title {
				width: 27rem;
				height: 5rem;
				margin: 0 auto;
				background-image: url('../../static/img/QAbg.webp');
				text-align: left;
				line-height: 5rem;
				padding-left: 1rem;
				font-size: 1.3rem;
				font-weight: bold;
				color: #000;
			}
			>h3{
				width: 25rem;
				height: 3rem;
				margin: 0 auto;
				text-align: left;
				line-height: 3rem;
				font-size: 1.1rem;
			}
			>ul{
				width: 23rem;
				height: auto;
				text-align: left;
				padding-right: 1rem;
				padding:0rem 1rem;
				>li{
					width: 100%;
					text-align: left;
					margin-bottom: 1rem;
					 text-indent: 2em;
				}
			}
		}
	}
</style>