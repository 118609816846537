import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/components/home.vue'
import Homes from '@/view/home/home.vue'
import Api from '@/view/center/api.vue'
import Developer from '@/view/center/developer.vue'
import Shop from '@/view/shop/shop.vue'
import Announce from '@/view/announce/announce.vue'
import Shoptype from '@/view/shoptype/shoptype.vue'
import Dynamic from '@/view/dynamic/dynamic.vue'
import goodSearch from '@/view/good_search'
import User from '@/view/user'
import Lyt from '@/view/lyt'


Vue.use(VueRouter)

const router = new VueRouter({
	routes: [{
			path: '/',
			name: 'Home',
			component: Home,
			children: [{
					path: '',
					component: Homes,
				},
				{
					path: '/apis/:id',
					component: Api,
				},
				{
					path: '/developer',
					component: Developer,
				},
				{
					path: '/shop',
					component: Shop,
				},
				{
					path: '/announce',
					component: Announce,
				},
				{
					path: '/shoptype',
					name: 'shoptype',
					component: Shoptype,
				},
				{
					path: '/dynamic',
					component: Dynamic,
				},
				{
					path: '/goodSearch',
					component: goodSearch,
				},
				{
					path: '/user',
					component: User,
				},
				{
					path: '/lyt',
					component: Lyt,
				},
			]
		},

	]
})


export default router